import React, {useEffect, useState} from 'react';
import {Link, withRouter, NavLink, useLocation} from "react-router-dom";
import "./header.css"
import BottomMenuOption from "../bottomMenuOptions/BottomMenuOption";
import {useMediaQuery} from 'react-responsive';


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


const allNavigations = [
    {
        id: 'about-navigation',
        title: 'About',
        link: '/about-us',
        childNavigations: []
    },
    {
        id: 'services-navigation',
        title: 'Services',
        link: '/services',
        childNavigations: [
            {
                title: 'Retirement Investments',
                link: '/services/retirement-investments'
            },
            {
                title: 'Wealth Creation',
                link: '/services/wealth-creation'
            },
            {
                title: 'Risk Management',
                link: '/services/risk-management'
            },
            {
                title: 'International Investments',
                link: '/services/international-investments'
            },
            {
                title: 'Global Tax Advisory',
                link: '/services/global-tax-advisory'
            },
            {
                title: 'Estate Planning',
                link: '/services/estate-planning'
            },
            {
                title: 'Treasury Operations for Corporates',
                link: '/services/treasury-operations-for-corporates'
            },

        ]
    },
    {
        id: 'process-navigation',
        title: 'Process',
        link: '/process-chart',
        childNavigations: []
    },
    {
        id: 'product-navigation',
        title: 'Products',
        link: '/products',
        childNavigations: [
            {
                title: 'Mutual funds',
                link: '/products/mutual-funds'
            },
            {
                title: 'Stocks',
                link: '/products/stocks'
            },
        ]
    },
    {
        id: 'select-your-profile-navigation',
        title: 'Profiles',
        link: '/select-your-profile',
        childNavigations: [
            {
                title: 'Business',
                link: '/select-your-profile/business',
                headerImage: 'business.jpg'
            },
            {
                title: 'For Her',
                link: '/select-your-profile/for-her',
                headerImage: 'forHer.jpg'
            },
            {
                title: 'Global',
                link: '/select-your-profile/global',
                headerImage: 'global.jpg'
            },
            {
                title: 'New Gen',
                link: '/select-your-profile/new-gen',
                headerImage: 'newGen.jpg'
            },
            {
                title: 'Professional',
                link: '/select-your-profile/professional',
                headerImage: 'professional.jpg'
            },
            {
                title: 'Salaried',
                link: '/select-your-profile/salaried',
                headerImage: 'salaried.jpg'
            },
            {
                title: 'Retiree',
                link: '/select-your-profile/retiree',
                headerImage: 'retire.jpg'
            },
        ]
    },
    {
        id: 'knowledge-bank-navigation',
        title: 'Knowledge Bank',
        link: '/knowledge-bank',
        childNavigations: [
            {
                title: 'Events',
                link: '/knowledge-bank/events'
            },
            {
                title: 'Articles',
                link: '/knowledge-bank/articles'
            },
            {
                title: 'Publications',
                link: '/knowledge-bank/publications'
            }
        ]
    },
    {
        id: 'contact-us-navigation',
        title: 'Contact Us',
        link: '/contact-us',
        childNavigations: []
    }
];

const allResponsiveNavigations = [
    {
        id: 'about-navigation',
        title: 'About',
        link: '/about-us',
        childNavigations: []
    },
    {
        id: 'services-navigation',
        title: 'Services',
        link: '/services',
        childNavigations: [
            {
                title: 'Retirement Investments',
                link: '/services/retirement-investments'
            },
            {
                title: 'Wealth Creation',
                link: '/services/wealth-creation'
            },
            {
                title: 'Risk Management',
                link: '/services/risk-management'
            },
            {
                title: 'International Investments',
                link: '/services/international-investments'
            },
            {
                title: 'Global Tax Advisory',
                link: '/services/global-tax-advisory'
            },
            {
                title: 'Estate Planning',
                link: '/services/estate-planning'
            },
            {
                title: 'Treasury Operations for Corporates',
                link: '/services/treasury-operations-for-corporates'
            },

        ]
    },
    {
        id: 'process-navigation',
        title: 'Process',
        link: '/process-chart',
        childNavigations: []
    },
    {
        id: 'product-navigation',
        title: 'Products',
        link: '/products',
        childNavigations: [
            {
                title: 'Mutual funds',
                link: '/products/mutual-funds'
            },
            {
                title: 'Stocks',
                link: '/products/stocks'
            },
        ]
    },
    // {
    //     id: 'select-your-profile-navigation',
    //     title: 'Profile',
    //     link: '/select-your-profile',
    //     childNavigations: [
    //         {
    //             title: 'Business',
    //             link: '/select-your-profile/business',
    //             headerImage: 'business.jpg'
    //         },
    //         {
    //             title: 'For Her',
    //             link: '/select-your-profile/for-her',
    //             headerImage: 'forHer.jpg'
    //         },
    //         {
    //             title: 'Global',
    //             link: '/select-your-profile/global',
    //             headerImage: 'global.jpg'
    //         },
    //         {
    //             title: 'New Gen',
    //             link: '/select-your-profile/new-gen',
    //             headerImage: 'newGen.jpg'
    //         },
    //         {
    //             title: 'Professional',
    //             link: '/select-your-profile/professional',
    //             headerImage: 'professional.jpg'
    //         },
    //         {
    //             title: 'Salaried',
    //             link: '/select-your-profile/salaried',
    //             headerImage: 'salaried.jpg'
    //         },
    //         {
    //             title: 'Retiree',
    //             link: '/select-your-profile/retiree',
    //             headerImage: 'retire.jpg'
    //         },
    //     ]
    // },
    {
        id: 'more-navigation',
        title: 'More',
        link: '--',
        childNavigations: [
            {
                title: 'Profiles',
                link: '/select-your-profile/business',
            },
            {
                title: 'Knowledge Bank',
                link: '/knowledge-bank/events'
            },
            {
                title: 'Contact Us',
                link: '/contact-us',
            }
        ]
    },

];

const visibleHeader = [
    {
        title: 'Sip',
        link: '/sip'
    },
      {
        title: 'Contact Us',
        link: '/contact-us',
    }
];


function LeftDesktopNavigations() {
    const {pathname, hash} = useLocation();
    const mainNavClassName = (isActive) => {
        if (isActive) {
            if (pathname === '/' && hash !== 'process-chart') {
                return "nav-link";
            }
            return "nav-link nav-link-active"
        }
        return "nav-link"
    };

    const activeDropdownClassName = (obj) => {
        if (pathname === obj.link) {
            return "active-dropdown-link nav-link-active ";
        }
        return "";
    };

    const isVisible = useMediaQuery({minWidth: 992, maxWidth: 1480});

    // const isChildActive = false;
    // const moreNavigation = allResponsiveNavigations.find(nav => nav.id === 'more-navigation');
    // const isPathnameInMoreNavigation = moreNavigation.childNavigations.find(link => link.link === pathname)
    //
    // console.log('22',isPathnameInMoreNavigation);

    return (
        <>
            {isVisible ?
                <ul className="navbar-nav me-auto primary-header-container d-flex flex-row">
                    {
                        allResponsiveNavigations.map((obj) => {

                            if (obj.childNavigations.length > 0) {

                                let isMoreOption = obj.id === 'more-navigation';
                                let isMoreOptionActive = false;
                                if (isMoreOption) {
                                    isMoreOptionActive = !!obj.childNavigations.find(e => (e.link === pathname || pathname.startsWith('/knowledge-bank/')) );
                                    console.log("e.link", obj, "pathname", pathname)
                                }
                                return (<li key={"container" + obj.id}
                                            className={(pathname.startsWith(obj.link) || isMoreOptionActive) ? "nav-link-active nav-item dropdown" : "nav-item dropdown"}>
                                    <a className={"nav-link dropdown-toggle "}
                                       id={obj.id}
                                       role="button"
                                       data-bs-toggle="dropdown"
                                       aria-expanded={false}
                                       href="#">
                                        {obj.title}
                                    </a>
                                    <ul className="dropdown-menu"
                                        aria-labelledby={obj.id}>
                                        {obj.childNavigations.map((subChild, index) => {
                                            return (<li key={"container-" + obj.id + "-" + index}
                                                        className={activeDropdownClassName(subChild)}>
                                                <Link to={subChild.link}
                                                      className="dropdown-item ">
                                                    <span className="dropdown-item primary-dropdown-color">
                                                        {subChild.title}
                                                    </span>
                                                </Link>
                                            </li>)
                                        })}
                                    </ul>
                                </li>)
                            }


                            return (<li key={"container" + obj.id} className="nav-item">
                                <NavLink to={obj.link}
                                         className={mainNavClassName}
                                         exact={true}>
                                    {obj.title}
                                </NavLink>
                            </li>);
                        })
                    }
                </ul>
                :
                <ul className="navbar-nav me-auto  primary-header-container d-flex flex-row">
                    {
                        allNavigations.map((obj) => {
                            if (obj.childNavigations.length > 0) {
                                return (<li key={"container" + obj.id}
                                            className={pathname.startsWith(obj.link) ? "nav-link-active nav-item dropdown" : "nav-item dropdown"}>
                                    <a className={"nav-link dropdown-toggle "}
                                       id={obj.id}
                                       role="button"
                                       data-bs-toggle="dropdown"
                                       aria-expanded={false}
                                       href="#">
                                        {obj.title}
                                    </a>
                                    <ul className="dropdown-menu"
                                        aria-labelledby={obj.id}>
                                        {obj.childNavigations.map((subChild, index) => {
                                            return (<li key={"container-" + obj.id + "-" + index}
                                                        className={activeDropdownClassName(subChild)}>
                                                <Link to={subChild.link}
                                                      className="dropdown-item ">
                                                <span
                                                    className="dropdown-item primary-dropdown-color"> {subChild.title}</span>
                                                </Link>
                                            </li>)
                                        })}
                                    </ul>
                                </li>)
                            }


                            return (<li key={"container" + obj.id} className="nav-item">
                                <NavLink to={obj.link}
                                         className={mainNavClassName}
                                         exact={true}>
                                    {obj.title}
                                </NavLink>
                            </li>);
                        })
                    }
                </ul>

            }
        </>

    )
}

function RightDesktopNavigations() {
    return (<div className="navbar-nav  d-flex flex-row  align-items-center"
                 style={{'--bs-scroll-height': '100px'}}>
        <li className="me-2">
            <a href="/sip">
                <button type="submit "
                        className="btn btn-dark button-css"
                        id="Calculator"
                        href="/sip">
                    SIP Calc
                </button>
            </a>
        </li>
        <li>
            <div className="dropdown">
                <a className="btn btn-outline-dark dropdown-toggle button-css me-2 d-flex align-items-center"
                    href="#" role="button" id="myacc"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    My Account
                </a>

                <ul className="dropdown-menu" aria-labelledby="myacc">
                    <li>
                        <a className="dropdown-item"
                           href="https://wealthelite.in/client-login"
                           target="_blank">Mutual funds</a>
                    </li>
                    <li>
                        <a className="dropdown-item"
                           href="https://www.angelone.in/login/"
                           target="_blank">Direct Equity</a>
                    </li>
                </ul>
            </div>
        </li>
        <li>
            <Link to="/contact-us">
                <button type="submit"
                        className="btn btn-outline-dark button-css">
                    Get in Touch
                </button>
            </Link>
        </li>

    </div>);
}

function SecondaryNavigation(props) {
    const {pathname} = useLocation();
    const {isMobileView, isXSView} = props;

    let foundParentNav = allNavigations.find((e) => pathname.startsWith(e.link));
    if (!foundParentNav) {
        return null;
    }
    if (foundParentNav.childNavigations.length === 0) {
        return null;
    }

    return (<div className="">
        <ul className={isMobileView ? "d-flex align-items-center  px-4 secondary-header-container py-2 mb-0"
            : "d-flex align-items-center  ps-0 py-3 secondary-header-container"}>
            {
                foundParentNav.childNavigations.map((secondRoute, index) => {
                    return (<li className="nav-link  secondary-header"
                                style={{fontSize: 14}}
                                key={'secondRoute-' + index}>
                        <NavLink to={secondRoute.link}
                                 className={(isActive) => {
                                     if (isActive) {
                                         return "dropdown-item-active"
                                     }
                                     return "dropdown-item"
                                 }}>
                            {secondRoute.title}
                        </NavLink>
                    </li>);
                })
            }
        </ul>
    </div>);
}


function isShowHeaderMobileView() {
    const {pathname} = useLocation();
    return !visibleHeader.some(item => item.link === pathname);
}

function HeaderView(props) {
    const {isMobileView, isXSView} = props;
    const {pathname} = useLocation();
    const isShowHeader=isShowHeaderMobileView();

    const getHeaderImage = () => {
        let headersWithColorImages = allNavigations.find(e => e.link === '/select-your-profile');
        let obj = headersWithColorImages.childNavigations.find(e => e.link === pathname);
        if (obj && obj?.headerImage) {
            return s3BaseURL + '/logo/' + obj.headerImage;
        }
        return s3BaseURL + '/logo/simplicity.jpg';
    };

    return (
        <div>

            {isMobileView ?
                <>
                    {isShowHeader &&
                    <header className="fixed-top"
                            style={{backgroundColor: "white"}}>
                        <div className=" d-flex justify-content-between align-items-center py-2"
                        style={{paddingRight:"12px", paddingLeft:'12px'}}>
                            <div className="logo-icon me-0 d-flex align-items-center">
                                <div>
                                    <Link to={"/"}>
                                        <img
                                            src={getHeaderImage()}
                                            alt=""
                                            height={54}
                                            width={32}
                                            className=" logo"/>
                                    </Link>
                                </div>
                                <div className="text-nowrap ps-1 header-site-name">Simplicity Investing</div>
                            </div>
                            <div className=" d-flex justify-content-end">
                                <Link to="/contact-us">
                                    <button type="submit"
                                            className="btn btn-outline-dark sign-up-button-mob py-1 px-2">
                                        Get in Touch
                                    </button>
                                </Link>
                            </div>
                        </div>
                        <div style={{borderBottom: "1px solid #BEBEBE"}}>
                            <SecondaryNavigation {...props}/>
                        </div>
                    </header>
                    }
                </>
                :

                <>
                    <div className="parent-container header fixed-top header-container"
                         style={{backgroundColor: "white", height: "140px"}}>
                        <div className="child-20 py-2">
                            <div className="logo-icon">
                                <Link className="navbar-brand"
                                      to="/">
                                    <img
                                        src={getHeaderImage()}
                                        alt=""
                                        className="img-fluid logo"/>
                                </Link>
                            </div>
                        </div>
                        <div className="child-80 py-2">
                            <div className="child-80-top">
                                <div className="d-flex " id="navbarScroll">
                                    <LeftDesktopNavigations/>
                                    <RightDesktopNavigations/>
                                </div>
                            </div>
                            <div className="child-80-bottom">
                                <SecondaryNavigation {...props}/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}


HeaderView.propTypes = {};

export default HeaderView;
