import React, {useRef, useState} from 'react';
import FooterView from "./footerView"
import {withRouter} from "react-router-dom";

import {connect} from "react-redux";

function Footer(props) {
    const [footerState, setFooterState] = useState({
        servicearr: [],
        email: 'info@simplicityinvesting.com',
        contact_number: '+91 9673582266',
        address: '6, Bhosale Heights, 1195 FC Road, Pune, Maharashtra 411005',
        favIconLink: 'favicon.ico',
        officeData: {},
        settingData: {},
        socailMedia: [],
        data: [],

    });
    return (
        <FooterView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(Footer));

