import React from 'react';
import "./footer.css"
import {Link} from "react-router-dom";
import FacebookIcon from "../../../assets/img/Footer/SocialIconFacebook.png";
import LinkdinIcon from "../../../assets/img/Footer/SocialIconLinkedin.png";
import YoutubeIcon from "../../../assets/img/Footer/SocialIconYoutube.png";
import InstagramIcon from "../../../assets/img/Footer/SocilaIconInstagram.png";

function FooterView(props) {
    const {isMobileView} = props;
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This makes the scroll smooth
        });
    };
    return (
        <div className="footer-container scroll-to-top">
            <div className="footer-x-padding">
                <div className="footer_di">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="footercard">
                                <p className="fot_bold">Contact Us</p>
                                <p className="d-flex pt-1">
                                    <i className="fa fa-envelope "
                                       style={{marginRight: '10px', color: "#FFFFFF"}}
                                       aria-hidden="true"/>
                                    <a href="mailto:info@simplicityinvesting.com"
                                       style={{marginTop: "-8px"}}
                                       className="footer-text text-break"
                                       target="_blank">info@simplicityinvesting.com
                                    </a>
                                </p>
                                <p>
                                    <i className="fa fa-phone"
                                       style={{marginRight: '10px', color: "#FFFFFF"}}
                                       aria-hidden="true"/>
                                    <a href="tel:+91 8408859836"
                                       className="footer-text">+91 8408859836</a>
                                </p>
                                <p className="foot_txt">
                                    <i className="fa-solid fa-location-dot"
                                       style={{marginRight: '10px', color: "#FFFFFF"}}
                                       aria-hidden="true"/>
                                    <a href="https://www.google.com/maps?q=6, Bhosale Heights, 1195 FC Road, Pune 411005 Maharashtra."
                                       className="footer-text"
                                       target="_blank">6,
                                        Bhosale Heights, 1195 FC Road, Pune 411005 Maharashtra.
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="footercard ">
                                <p className="fot_bold">Products</p>
                                <div className={isMobileView ? "d-flex align-items-center" : ''}>
                                    <Link to={"/products/mutual-funds"}>
                                        <p className="footer-text"
                                           style={{paddingRight: isMobileView ? '31px' : '',marginTop:'-5px'}}>Mutual funds</p>
                                    </Link>
                                    <Link to={"/products/stocks"}>
                                        <p className="footer-text"
                                           style={{paddingRight: isMobileView ? '31px' : ''}}> Stocks</p>
                                    </Link>
                                    <Link to={"/sip"}>
                                        <p className="sip"> SIP Calculator</p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-7">
                            <div className="footercard">
                                <p className="fot_bold">Services</p>
                                <Link to={"/services/retirement-investments"}>
                                    <p className="fot_text footer-text"
                                       style={{marginTop:'-5px'}}>Retirement Investments</p>
                                </Link>
                                <Link to={"/services/wealth-creation"}>
                                    <p className="fot_text footer-text"> Wealth Creation </p>
                                </Link>
                                <Link to={"/services/risk-management"}>
                                    <p className="fot_text footer-text">Risk Management </p>
                                </Link>
                                <Link to={"/services/international-investments"}>
                                    <p className="fot_text footer-text"> International Investments </p>
                                </Link>
                                <Link to={"/services/global-tax-advisory"}>
                                    <p className="fot_text footer-text"> Global Tax Advisory </p>
                                </Link>
                                <Link to={"/services/estate-planning"}>
                                    <p className="fot_text footer-text"> Estate Planning </p>
                                </Link>
                                <Link to={"/services/treasury-operations-for-corporates"}>
                                    <p className="fot_text footer-text"> Treasury Operations for Corporates </p>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-6 col-5">
                            <div className="footercard">
                                <p className="fot_bold"> Company</p>
                                <Link to={"/about-us"}>
                                    <p className="fot_text footer-text"
                                       style={{marginTop:'-5px'}}> About </p>
                                </Link>
                                <Link to={"/select-your-profile/business"}>
                                    <p className="fot_text footer-text" onClick={scrollToTop}> Select your Profile </p>
                                </Link>
                                <Link to={"/knowledge-bank/events"}>
                                    <p className="fot_text footer-text" onClick={scrollToTop}> Knowledge Bank </p>
                                </Link>
                                <Link to={"/contact-us"}>
                                    <p className="fot_text footer-text"> Contact Us </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{marginBottom: isMobileView ? "100px" : ''}}>
                    <div className="socal_icon">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-12">
                                <ul className="list-inline social_icon  d-flex" style={{gap: 8}}>
                                    <li>
                                        <a href="https://www.facebook.com/simplicityinvesting/"
                                           target="_blank">
                                            <img src={FacebookIcon}
                                            height={34}
                                            width={34}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/simplicity_investing/?hl=en"
                                           target="_blank">
                                            <img src={InstagramIcon}
                                                 height={34}
                                                 width={34}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/simplicity-investing-advisors-india-private-limited"
                                           target="_blank">
                                            <img src={LinkdinIcon}
                                                 height={34}
                                                 width={34}/>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/channel/UCgZWJrYWrt-HzKpcOpy9-CA"
                                           target="_blank">
                                            <img src={YoutubeIcon}
                                                 height={34}
                                                 width={34}/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-md-6 col-lg-4 col-12">
                                <div className="cop_text" style={{textAlign: "start"}}>
                                    <p>©2022 Simplicity Investing India Private Limited</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-2"/>
                        </div>

                    </div>
                </div>
            </div>
        </div>


    );
}

FooterView.propTypes = {};

export default FooterView;
