import React, {useEffect} from 'react';
import {Table, TableHead, TableRow} from "@mui/material";
import moment from "moment";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function ConsistencyBetter(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const element=props.value;

    const renderTable=(props)=>
    {   const {allocation}=props
        return(
        <Table className='table-subBlock'>
            <TableHead>
                <TableRow>
                    <th style={{borderBottom: 'none', textAlign: 'left'}}>Stock/Fund</th>
                    <th style={{borderBottom: 'none', textAlign: 'left'}}>Allocation</th>
                </TableRow>
            </TableHead>
            <tbody>
            {allocation.map((item, index) => (
                <tr key={index}>
                    <td style={{border: 'none'}}>{item.stock_fund || 'Total'}</td>
                    <td style={{border: 'none'}}>{item.percentage || item.total}%</td>
                </tr>
            ))}
            </tbody>
        </Table>)
    }
    return (
        <div>
            {element.map((e,index)=>
                <div className="mb-5" key={index}>
                    <div className='page-title py-3'>{e.heading}</div>
                    <div className='date mb-3'>
                        <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                        <span className="px-2">
                            {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                        </span>
                        </div>
                        <img className='w-100 h-100 img-fluid mb-3' src={s3BaseURL + e.image}/>
                    <div>
                        <div className="content mb-3">{e.description}</div>
                        <div className="content mb-3">{e.description1}</div>
                        <div className="content mb-3">{e.description2}</div>
                    </div>
                    <div className='d-flex justify-content-center mb-3'>
                        {renderTable(e.data.table1)}
                    </div>
                        <div>
                            <div className="content mb-3">{e.data.description1}</div>
                            <div className="content mb-3">{e.data.description2}</div>
                            <div className="content mb-3">{e.data.description3}</div>
                            <div className="content mb-3">{e.data.description4}</div>
                            <div className="content mb-3">{e.data.description5}</div>
                            <div className="content mb-3">{e.data.description6}</div>
                        </div>

                </div>
            )

            }
        </div>
    );
}

export default ConsistencyBetter;
