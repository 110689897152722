import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import "../../assets/css/SelectYourProfile.css";
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function SalariedView(props) {
    const [showUI, setShowUI] = useState(false);
    const {isMobileView, isXSView} = props;


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            {!showUI &&
            <div className="container-x">
                <div className="bg-salaried">
                    <div className="head-block-select-para">
                        <div className="page-title text-center my-3">
                            Take care of everyone in your family
                        </div>
                        <p className="para-select-profile mb-4">
                            Create a solid financial plan for all of your family goals
                        </p>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-dark-red"
                                    onClick={displayUI}>
                                READ MORE
                            </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/salariedPrimaryBg.jpg'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>

            }
            {showUI && <div className="container-x"
                            style={{height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="page-title my-3">
                            Being cautious may
                            seem safe, but it’s risky
                        </div>
                        <div className="content mb-3">
                            As a salaried family member, you have a limited source of income, and
                            multiple responsibilities. As a result, it’s likely that you have been
                            defensive in your investment approach and have invested in products like
                            insurance and bank deposits.
                        </div>
                        <div className="content mb-3">
                            A defensive approach can create more problems when the economy has inflation,
                            making it even more difficult to meet future needs.
                        </div>
                        <div className="content mb-3">
                            To overcome this, the approach needs to be adjusted toward more growth
                            investments. And we will work with you to facilitate this change.
                        </div>
                        <div className="contact-us-text mb-3">Get in touch with us
                            <a href="/contact-us" className="contact-us">Click Here</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                                <img src={s3BaseURL + '/selectYourProfile/salariedSecondaryBg.jpg'}
                                     className="img-fluid img img-container"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {renderFooter()}
        </div>
    );
}

SalariedView.propTypes = {};

export default SalariedView;
