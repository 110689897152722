import React, {useEffect, useState} from 'react';
import "../../assets/css/SelectYourProfile.css";
import {Button} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function ProfessionalView(props) {
    const [showUI, setShowUI] = useState(false);
    const {isMobileView, isXSView} = props;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            {!showUI &&
            <div className="container-x">
                <div className="bg-professional">
                    <div className="head-block-select-para">
                        <div className="page-title text-center my-3">
                            Widen your wealth and
                            estate landscape
                        </div>
                        <div className="para-select-profile mb-4">
                            Invest like a professional and broaden <br/> your life choices
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-light-blue"
                                     onClick={displayUI}>
                                Read More
                            </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/professionalPrimaryBg.png'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>


            }
            {showUI &&
            <div className="container-x"
                 style={{height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                <div className="row ">
                    <div className="col-lg-6 col-12">
                        <div className="page-title my-3">
                            Over-diversification results in ordinary results
                        </div>
                        <div className="content mb-3">
                            Professionals like you generally spread investments across
                            multiple products due to social interactions. Having many
                            investment products sounds like a good idea. But too many
                            products across too many asset classes is usually a symptom
                            of a lack of focus. And this results in below average returns.
                        </div>
                        <div className="content mb-3">
                            Using our simple approach, we will identify quality and not
                            quantity. Outcomes can include creating a stream of income
                            to replace your professional income when you retire, income
                            for sending children abroad for education, and even a global
                            wealth portfolio that will go to your next generation. We
                            design estate portfolios to minimize taxes, currency risk and
                            inflation risk, and provide Family Wealth Office services.
                        </div>
                        <div className="contact-us-text mb-3">Get in touch with us
                            <a href="/contact-us" className="contact-us">Click Here</a>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="d-none d-lg-block">
                            <div className="d-flex justify-content-center">
                            <img src={s3BaseURL + '/selectYourProfile/professionalSecondaryBg.jpg'}
                                 className="img-fluid img img-container"
                                 alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {renderFooter()}
        </div>
    );
}

ProfessionalView.propTypes = {};

export default ProfessionalView;
