import React, {useState} from 'react';
import WhitePapersView from "./WhitePapersView";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


function WhitePapers(props) {
    const [whitePaperPageState, setWhitePaperPageState] = useState({
        whitePapers: [],
        whitePapersPdf: [],
    });
    return (
        <WhitePapersView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    searchWhitepaperText:state.appState.searchWhitepaperText
});

export default connect(mapStateToProps, {})(withRouter(WhitePapers));
