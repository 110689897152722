import React, {useState} from 'react';
import EventView from "./EventView"
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";


function Event(props) {
    const [eventPageState, setEventPageState] = useState({
        public: [],
        hidelodmore: '',
        videoLink: '',
        Events: [],
        EventsUrl: [],
        conditionEvents: '',
        loadmoredata: [],
        data: {current_page: 1},
        url: [],

    });
    return (
        <EventView {...props}/>
        );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    searchEventText: state.appState.searchEventText,
});

export default connect(mapStateToProps, {})(withRouter(Event));
