import React, {useEffect} from 'react';
import moment from "moment/moment";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function RelationshipEmotional(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const element=props.value;
    return (
        <div>
            {element.map((e,index)=>
                <div className="mb-5" key={index}>
                    <div className='page-title py-3'>{e.heading}</div>
                    <div className='date mb-3'>
                        <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                        <span className="px-2">
                            {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                        </span>
                    </div>
                    <img className='img-fluid w-100 mb-3' src={s3BaseURL + e.image}/>
                        <div className="content mb-3">{e.data.description}</div>
                    <div className="content mb-3">{e.data.description1}</div>
                    <div className="content mb-3">{e.data.description2}</div>
                    <div className="content mb-3">{e.data.description3}</div>
                    <div className="content mb-3">{e.data.description4}</div>
                    <div className="content mb-3">{e.data.description5}</div>
                    <div className="content mb-3">{e.data.description6}</div>
                    <div className="content mb-3">{e.data.description7}</div>
                    <div className="content mb-3">{e.data.description8}</div>
                    <div className="content mb-3">{e.data.description9}</div>
                    <div className="content mb-3">{e.data.description10}</div>
                    <div className="content mb-3">{e.data.description11}</div>
                    <div className="content mb-3">{e.data.description12}</div>
                    <div className="content mb-3">{e.data.description13}</div>
                    <div className="content mb-3">{e.data.description14}</div>
                    <div className="section-header mb-3">{e.data.heading1}</div>
                    <div className="section-header mb-3">{e.data.heading2}</div>
                </div>
            )

            }
        </div>
    );
}

export default RelationshipEmotional;
