import React, {useEffect, useRef} from 'react';
import {Link, withRouter, NavLink, useLocation} from "react-router-dom";
import "../assets/css/HomePage.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../core/layout/footer/footer";
import SelectYourProfileCarousal from "./SelectYourProfileCarousal";
import 'react-toastify/dist/ReactToastify.css';
import data from "../knowledgeBank/whitepapers/WhitePaperModule";
import articlesData from "../knowledgeBank/blogs/BlogModule"

import Snackbar, {SnackbarOrigin} from '@mui/material/Snackbar';
import {Typography, Box} from '@mui/material';
import store from "../store";
import ProcessImgMobile from "../../src/assets/img/Homepage/processImgMobile.png";
import rightTick from '../assets/img/rightTick.png'
import moment from "moment";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


const CustomRightArrow = React.forwardRef((props, ref) => {
    const {onClick} = props;
    return (
        <div className="owl-prev" onClick={onClick}>
            <i ref={ref}
               className="fa-solid fa-angle-right custom-arrow "
               aria-hidden="true"
            />
        </div>
    );
});

const CustomLeftArrow = React.forwardRef((props, ref) => {
    const {onClick} = props;
    return (
        <div className="owl-prev" onClick={onClick}>
            <i ref={ref}
               className="fa-solid fa-angle-left custom-arrow cursor"
               aria-hidden="true"
            />
        </div>
    );
});

function scrollToSection() {
    const section = document.getElementById('#process-chart');
    if (section) {
        section.scrollIntoView({behavior: 'smooth'});
    }
}


function HomePageView(props) {
    const {pathname, hash} = useLocation();
    const carouselRef = useRef(null);
    const rightArrowRef = useRef(null);
    const leftArrowRef = useRef(null);
    const {isMobileView, isXSView} = props;

    const records = data.slice(0, 3);
    const articlesRecord = articlesData.slice(0, 3);

    const [state, setState] = React.useState({
        open: false,
        vertical: 'top',
        horizontal: 'right',
    });
    const {vertical, horizontal, open} = state;

    const handleClick = (newState) => () => {
        setState({...newState, open: true});
    };

    const handleClose = () => {
        setState({...state, open: false});
    };


    const copyToClipboard = (url) => {

        navigator.clipboard.writeText(url)
            .then(
                handleClick({vertical: 'top', horizontal: 'right'})
            )
            .catch(err => {
                console.error('Error copying text: ', err);
            });
    };

    const responsive = {
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 4,
            slidesToSlide: 1 // optional, default to 1.
        },
        tablet: {
            breakpoint: {max: 1024, min: 576},
            items: 2,
            slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
            breakpoint: {max: 576, min: 0},
            items: 1,
            slidesToSlide: 1 // optional, default to 1.
        }
    };

    useEffect(() => {
        const targetSectionId = "ProcessChart";  // ID of the section to scroll to
        const targetHash = "#process-chart";  // URL that triggers the scroll
        if (hash === targetHash) {
            const targetElement = document.getElementById(targetSectionId);
            if (targetElement) {
                setTimeout(() => {
                    const element = document.getElementById(targetSectionId);
                    const offset = 180;
                    const bodyRect = document.body.getBoundingClientRect().top;
                    const elementRect = element.getBoundingClientRect().top;
                    const elementPosition = elementRect - bodyRect;
                    const offsetPosition = elementPosition - offset;

                    window.scrollTo({
                        top: offsetPosition,
                        behavior: 'smooth'
                    });
                }, 100);
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [hash, pathname]);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };


    return (
        <div>
            <section id={"homepage-banner-container"}>
                {
                    isMobileView ? <div style={{marginBottom: "99px"}}/> : <div className="topsection"/>
                }
                <div className=" homepage-banner-img banner-bottom text-center">
                    <div className="container">
                        <div className="page-title text-center">
                            Forget the past. <br/>
                            Investing is about the future.
                        </div>
                        <div className="banner-description-container content my-3">
                            Simplicity Investing is managed by people who have worked in
                            the field of investments for their entire lives.
                            We use our global knowledge to predict
                            the future of commerce to create wealth for our clients .
                        </div>
                        {isMobileView ?
                            <div>
                                <a href="/contact-us">
                                    <button className="btn btn-outline-dark btn-lg w-100 py-3 mb-3 font-bolder">
                                        SIGN UP FOR A FREE CONSULTATION
                                    </button>
                                </a>
                                <a href="/about-us">
                                    <button className="btn btn-outline-dark btn-lg w-100 py-3 font-bolder">
                                        LEARN MORE
                                    </button>
                                </a>
                            </div> :
                            <div className="text-center">
                                <a href="/contact-us">
                                    <button className="btn btn-outline-dark btn-lg font-bolder ">
                                        SIGN UP FOR A FREE CONSULTATION
                                    </button>
                                </a>
                                <a href="/about-us">
                                    <button className="btn btn-outline-dark btn-lg ms-2 font-bolder">
                                        LEARN MORE
                                    </button>
                                </a>
                            </div>
                        }
                        <div className="py-4">
                            {isMobileView ?
                                <div className="sub-description-text  p-3 section-bg">
                                    Simplicity Investing India Private Limited is an India-based investment company, an
                                    AMFI registered mutual fund distributor, and a SEBI registered stock sub-broker.
                                </div>
                                :
                                <div className="sub-description-text ">
                                    Simplicity Investing India Private Limited is an India-based investment company,
                                    an<br/>
                                    AMFI registered mutual fund distributor, and a SEBI registered stock sub-broker.
                                </div>
                            }
                        </div>
                        <div className="customized-focus-text">
                            Customized investing focused on you
                        </div>
                        <div className="tags">
                            <Link to="select-your-profile/business">
                                <img src={s3BaseURL + '/logo/business.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                            <Link to="/select-your-profile/for-her">
                                <img src={s3BaseURL + '/logo/forHer.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                            <Link to="/select-your-profile/global">
                                <img src={s3BaseURL + '/logo/global.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                            <Link to="/select-your-profile/new-gen">
                                <img src={s3BaseURL + '/logo/newGen.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                            <Link to="/select-your-profile/professional">
                                <img src={s3BaseURL + '/logo/professional.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                            <Link to="/select-your-profile/salaried">
                                <img src={s3BaseURL + '/logo/salaried.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                            <Link to="/select-your-profile/retiree">
                                <img src={s3BaseURL + '/logo/retire.jpg'}
                                     alt=""
                                     className="logo-img img-fluid"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>

            <div className="container">
                <section className="section-padding-bottom">
                    <div className="section-padding-top row">
                        <div className={isMobileView ? "d-flex justify-content-center" : "d-flex justify-content-between"}>
                            <h2 className="page-title" style={{marginBottom: isMobileView ? "" :'70px'}}> Services We Offer</h2>
                            {isMobileView ? <div/> :
                                <div className="d-flex align-items-center justify-content-end">
                                    <CustomLeftArrow ref={leftArrowRef} onClick={() => carouselRef.current.previous()}/>
                                    <CustomRightArrow ref={rightArrowRef} onClick={() => carouselRef.current.next()}/>
                                </div>
                            }
                        </div>
                        <>
                            <Carousel
                                ref={carouselRef}
                                swipeable={false}
                                draggable={false}
                                showDots={false}
                                responsive={responsive}
                                ssr={false}
                                customRightArrow={<CustomRightArrow/>}
                                customLeftArrow={<CustomLeftArrow/>}
                                infinite={true}
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                transitionDuration={1000}
                                containerClass="carousel-container"
                                removeArrowOnDeviceType={["tablet", "mobile"]}>

                                <div className="card card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/retirementInvestments.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle">
                                                Retirement Investments
                                            </h5>
                                            <p className="card_text mt-2 mb-0 h-100">
                                                We all need to save for the golden phase of our lives
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/retirement-investments">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/wealthCreation.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle mb-0">
                                                Wealth Creation
                                            </h5>
                                            <p className="card_text mt-2 h-100">
                                                To create wealth, we will help you on a financial investment process to
                                                build
                                                your base
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/wealth-creation">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/riskManagement.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle mb-0">
                                                Risk Management
                                            </h5>
                                            <p className="card_text mt-2 h-100">
                                                You have built a base, we will work to make it secure and grow without
                                                taking
                                                undue risks
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/risk-management">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/internationalInvestment.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle mb-0">
                                                International Investments
                                            </h5>
                                            <p className="card_text mt-2 h-100">
                                                We also provide services for investing in global markets by setting up
                                                accounts
                                                with international broking houses
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/international-investments">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/globalTaxAdvisory.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle mb-0">
                                                Global Tax Advisory
                                            </h5>
                                            <p className="card_text mt-2 h-100">
                                                We provide global tax advisory services
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/global-tax-advisory">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/estatePlanning.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle mb-0">
                                                Estate Planning
                                            </h5>
                                            <p className="card_text mt-2 h-100">
                                                The creation of wills, trusts, estate planning, and estate management
                                                services
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/estate-planning">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="card card-shadow card-container">
                                    <div className="image-carousel-container">
                                        <img src={s3BaseURL + '/homepage/treasuryOperationForCorporates.jpg'}
                                             className="img-fluid img"
                                        />
                                    </div>
                                    <div className="card-body text-center">
                                        <div>
                                            <h5 className="cardtitle mb-0">
                                                Treasury Operations for Corporates
                                            </h5>
                                            <p className="card_text mt-2 h-100">
                                                We will make sure that your cash flow is positive during the different
                                                business
                                                cycles of your industry
                                            </p>
                                        </div>
                                        <div>
                                            <a href="/services/treasury-operations-for-corporates">
                                                <button className="btn btn-outline-dark mt-2">SEE MORE</button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </Carousel>
                        </>
                        {isMobileView ?
                            <div className="d-flex align-items-center justify-content-center ">
                                <CustomLeftArrow ref={leftArrowRef} onClick={() => carouselRef.current.previous()}/>
                                <CustomRightArrow ref={rightArrowRef} onClick={() => carouselRef.current.next()}/>
                            </div>
                            : <div/>
                        }

                    </div>
                </section>

                <section className="section-padding-bottom">
                    <div className="text-center"
                         style={{paddingBottom: isMobileView ? "21px" : "90px"}}>
                        <div className="section-title">What Our Clients Are Saying</div>
                    </div>
                    <SelectYourProfileCarousal/>
                </section>

                <section className="section-padding-bottom" id="ProcessChart">
                    <div className="section-title text-center">
                        How we create and manage wealth
                    </div>
                    <div className="section-description-text text-center section-description-padding">
                        Simplicity’s core strength is in depth knowledge on global financial markets and
                        the ability to connect {isMobileView ? '' : <br/>} this expertise with different client profiles
                    </div>
                    <div>
                        {isMobileView ?
                            <div className="text-center">
                                <img src={s3BaseURL + '/homepage/processImgMobile.png'}
                                     className="img-fluid " alt=""
                                />
                            </div>
                            :
                            <img src={s3BaseURL + '/homepage/manageWealth.jpg'}
                                 alt=""
                                 style={{width: "100%", marginTop: "20px"}}
                            />
                        }
                    </div>
                </section>
            </div>

            <section className="section-padding-bottom knowledge-bank-container">
                <div className="px-3 px-xl-5 ">
                    <div className="section-title text-center">
                        What’s New at Simplicity Investing
                    </div>
                    <div className="section-description-text text-center section-description-padding px-1 mx-1  px-md-5 mx-md-5">
                        In an ever changing world with all kinds of events happening,
                        we continuosly share with you what it means for your investment portfolio
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-12">
                            <div className="knowledge-bank-sub-container">
                                <div className="knowledge-bank-header-box">
                                    <h3 className="knowledge-bank-sub-container-header">Articles</h3>
                                    <div className={isMobileView ? "d-none" :"d-block"}>
                                        <a href="/knowledge-bank/articles">
                                            <button className="btn btn-outline-dark ">
                                                Read More
                                            </button>
                                        </a>
                                    </div>
                                </div>

                                { articlesRecord.map((e,index)=>
                                    <div className="d-flex pb-5">
                                        <div className="img-blog cursor">
                                            <a href={e.url}>
                                                <img  src={ s3BaseURL + e.image}
                                                     className="img-fluid"
                                                     height={"100%"}
                                                     style={{aspectRatio:16/9}} alt=""/>
                                            </a>
                                        </div>
                                        <div className="text-start w-100 ps-3">
                                            <a href={e.url}
                                               className="blog-text cursor blog-text-truncate"> {e.heading}</a>
                                            <div className="date-container mt-1 d-flex justify-content-between align-items-center">
                                                <div>
                                                    <i className="fa-regular fa-calendar"/>
                                                    &nbsp;
                                                    {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}

                                                </div>
                                                <i className="fa-regular fa-copy cursor"
                                                   onClick={() => copyToClipboard(s3BaseURL + '/homepage/primaryBlog.png')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )
                                }




                                {isMobileView ?
                                    <div className="text-center mt-5">
                                        <a href={"/knowledge-bank/events"}>
                                            <button className="btn btn-outline-dark ">Read More</button>
                                        </a>
                                    </div>
                                    : ''
                                }
                            </div>

                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="knowledge-bank-sub-container">
                                <div className="knowledge-bank-header-box">
                                    <h3 className="knowledge-bank-sub-container-header">Publications</h3>
                                    <div className={isMobileView ? "d-none" :"d-block"}>
                                        <a href="/knowledge-bank/publications">
                                            <button className="btn btn-outline-dark ">
                                                Read More
                                            </button>
                                        </a>
                                    </div>
                                </div>


                               { records.map((e,index)=>
                                <div className="d-flex pb-5">
                                    <div className="img-blog">
                                        <iframe _ngcontent-cof-c37="" width={isMobileView ? "115" : "161"}
                                                frameBorder="0" allowFullScreen=""
                                                className="blog_img"
                                                height={"100%"}
                                                style={{aspectRatio:16/9}}
                                                src={ s3BaseURL + e.image}
                                        />
                                    </div>
                                    <div className="text-start w-100 ps-3">
                                        <a href={s3BaseURL + "/publications/1700893090.pdf"}
                                           className="blog-text cursor blog-text-truncate"
                                           target={"_blank"}>
                                            {e.title}
                                        </a>
                                        <div className="date-container mt-1 d-flex justify-content-between align-items-center">
                                            <div>
                                                <i className="fa-regular fa-calendar"/>
                                                &nbsp;
                                                {moment(e.date, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                            </div>
                                            <i className="fa-regular fa-copy cursor"
                                               onClick={() => copyToClipboard(s3BaseURL + e.image)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                )
                               }

                                {isMobileView ?
                                    <div className="text-center mt-5">
                                        <a href="/knowledge-bank/publications">
                                            <button className="btn btn-outline-dark ">
                                                Read More
                                            </button>
                                        </a>
                                    </div> : ''
                                }
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-12">
                            <div className="knowledge-bank-sub-container">
                                <div className="knowledge-bank-header-box">
                                    <h3 className="knowledge-bank-sub-container-header">Events</h3>
                                    <div className={isMobileView ? "d-none" :"d-block"}>
                                        <a href={"/knowledge-bank/events"}>
                                            <button className="btn btn-outline-dark ">View More</button>
                                        </a>
                                    </div>
                                </div>
                                <div className="d-flex pb-5">
                                    <div className="img-blog cursor">
                                        <iframe src='https://www.youtube.com/embed/f2nvrnsrIBs'
                                                width="161"
                                                height={"100%"}
                                                style={{aspectRatio:16/9}}
                                                allowFullScreen={true}
                                                className="blog_img img-fluid">
                                        </iframe>
                                    </div>
                                    <div className="text-start w-100 ps-3">
                                        <p className="blog-text blog-text-truncate mb-0">
                                            Unlocking Creativity: Building Blocks of Learning at our LEGO Event for Kids
                                        </p>
                                        <div className="date-container mt-1 d-flex align-items-center justify-content-between">
                                            <div>
                                                <i className="fa-regular fa-calendar"/>
                                                &nbsp; May 19, 2023
                                            </div>
                                            <i className="fa-regular fa-copy cursor"
                                               onClick={() => copyToClipboard('https://www.youtube.com/embed/f2nvrnsrIBs')}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex pb-5">
                                    <div className="img-blog cursor">
                                        <iframe src='https://www.youtube.com/embed/ViIILDy-i9s'
                                                width="161"
                                                allowFullScreen={true}
                                                height={"100%"}
                                                style={{aspectRatio:16/9}}
                                                className="blog_img img-fluid">
                                        </iframe>
                                    </div>
                                    <div className="text-start w-100 ps-3">
                                        <p className="blog-text blog-text-truncate mb-0">Financial Market Update - How
                                            the Markets Are Going to
                                            Be in 2023?</p>
                                        <div className="date-container mt-1 d-flex align-items-center justify-content-between">
                                            <div>
                                                <i className="fa-regular fa-calendar"/>
                                                &nbsp; May 13, 2023
                                            </div>
                                            <i className="fa-regular fa-copy cursor"
                                               onClick={() => copyToClipboard('https://www.youtube.com/embed/ViIILDy-i9s')}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="img-blog cursor">
                                        <iframe src='https://www.youtube.com/embed/tfqb2Eluv0Y'
                                                width="161"
                                                height={"100%"}
                                                style={{aspectRatio:16/9}}
                                                allowFullScreen={true}
                                                className="blog_img img-fluid ">
                                        </iframe>
                                    </div>
                                    <div className="text-start w-100 ps-3">
                                        <p className="blog-text blog-text-truncate mb-0">We Did a LEGO Event at our
                                            Hinjewadi, Pune Branch</p>
                                        <div className="date-container mt-1 d-flex align-items-center justify-content-between">
                                            <div>
                                                <i className="fa-regular fa-calendar"/>
                                                &nbsp; Apr 24, 2023
                                            </div>
                                            <i className="fa-regular fa-copy cursor"
                                               onClick={() => copyToClipboard('https://www.youtube.com/embed/tfqb2Eluv0Y')}/>

                                        </div>
                                    </div>
                                </div>
                                {isMobileView ?
                                    <div className="text-center mt-5">
                                        <a href={"/knowledge-bank/events"}>
                                            <button className="btn btn-outline-dark ">View More</button>
                                        </a>
                                    </div> : ''
                                }
                            </div>

                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{vertical, horizontal}}
                    autoHideDuration={2000}
                    open={open}
                    onClose={handleClose}
                    message={<Box display="flex" alignItems="center">
                             <img src={rightTick} alt="Right Tick" style={{marginRight: 10, width: 25, height: 20}}/>
                             <Typography sx={{color: 'white', fontSize: 18, fontFamily: 'Poppins'}}>Link Copied!</Typography>
                             </Box>
                             }
                    sx={{
                        '& .MuiSnackbarContent-root': {
                            backgroundColor: '#51A351',
                            width: '330px',
                        }
                    }}
                    key={vertical + horizontal}
                />
            </section>
            {renderFooter()}
        </div>);
}

HomePageView.propTypes = {};

export default HomePageView;
