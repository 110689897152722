import React, {useState, useRef} from 'react';
import "./bottomMenuOtion.css"
import {Link, withRouter, NavLink, useLocation} from "react-router-dom";
import ServicesIcon from "../../../assets/img/servicesIcon.png";
import ActiveServicesIcon from "../../../assets/img/activeServicesIcon.png";
import ProductsIcon from "../../../assets/img/productsIcon.png";
import ActiveProductsIcon from "../../../assets/img/activeProductsIcon.png";
import ProcessIcon from "../../../assets/img/processIcon.png";
import ActiveProcessIcon from "../../../assets/img/activeProcessIcon.png";
import AboutIcon from "../../../assets/img/aboutIcon.png";
import ActiveAboutIcon from "../../../assets/img/activeAboutIcon.png";
import MoreIcon from "../../../assets/img/moreIcon.png";
import Drawer from '@mui/material/Drawer';

const bottomMenuOptions = [
    {
        id: 'about-navigation',
        title: 'About',
        link: '/about-us',
        icon: AboutIcon,
        actionIcon: ActiveAboutIcon,
    },
    {
        id: 'services-navigation',
        title: 'Services',
        link: '/services/retirement-investments',
        icon: ServicesIcon,
        actionIcon: ActiveServicesIcon,
        childNavigations: [
            {
                title: 'Retirement Investments',
                link: '/services/retirement-investments'
            },
            {
                title: 'Wealth Creation',
                link: '/services/wealth-creation'
            },
            {
                title: 'Risk Management',
                link: '/services/risk-management'
            },
            {
                title: 'International Investments',
                link: '/services/international-investments'
            },
            {
                title: 'Global Tax Advisory',
                link: '/services/global-tax-advisory'
            },
            {
                title: 'Estate Planning',
                link: '/services/estate-planning'
            },
            {
                title: 'Treasury Operations for Corporates',
                link: '/services/treasury-operations-for-corporates'
            },

        ]
    },
    {
        id: 'process-navigation',
        title: 'Process',
        link: '/process-chart',
        icon: ProcessIcon,
        actionIcon: ActiveProcessIcon,
    },

    {
        id: 'product-navigation',
        title: 'Products',
        link: '/products/mutual-funds',
        icon: ProductsIcon,
        actionIcon: ActiveProductsIcon,
        childNavigations: [
            {
                title: 'Mutual funds',
                link: '/products/mutual-funds'
            },
            {
                title: 'Stocks',
                link: '/products/stocks'
            },
        ]
    },



];

const moreMenuOptions = [
    {
        id: 'my-acount-navigation',
        title: 'My Account',
        link: '/my-account',
    },
    {
        id: 'select-your-profile-navigation',
        title: 'Select Your Profile',
        link: '/select-your-profile/business'
    },
     {
        id: 'knowledge-bank-navigation',
        title: 'Knowledge Bank',
        link: '/knowledge-bank/events'
    },
    {
        id: 'sip-calc-navigation',
        title: 'SIP Calculator',
        link: '/sip'
    },
    {
        id: 'contact-us-navigation',
        title: 'Contact Us',
        link: '/contact-us'
    }
];

const visibleBottomMenu = [
    {
        title: 'sip',
        link: '/sip'
    },
    {
        title: 'Contact Us',
        link: '/contact-us',
    }
]

function isShowHeaderMobileView() {
    const {pathname} = useLocation();
    return !visibleBottomMenu.some(item => item.link === pathname);
}

function BottomMenuOptionView(props) {
    const [openMoreDriver, setOpenMoreDriver] = useState(false);
    const ref = useRef();
    const {pathname, hash} = useLocation();


    const handleClick = () => {
        setOpenMoreDriver(true);
    };

    const handleClickClose = () => {
        setOpenMoreDriver(false);
    };

    const isMoreActive = () => {
        let pathsIncludedInMore = ['/my-account',  '/knowledge-bank', '/select-your-profile', '/contact-us', '/sip'];
        if (pathsIncludedInMore.find((e) => e === pathname || pathname.startsWith(e) || e === hash)) {
            return 'bottom-menu bottom-menu-active-border-top';
        }
        return 'bottom-menu';
    };

    const mainNavClassName = (bottomMenuOption) => {
        if (bottomMenuOption.link === pathname) {
            return {class: 'bottom-menu bottom-menu-active-border-top', icon: bottomMenuOption.actionIcon};
        }
        if (bottomMenuOption.childNavigations) {
            let obj = bottomMenuOption.childNavigations.find((e) => e.link === pathname);
            if (obj) {
                return {class: 'bottom-menu bottom-menu-active-border-top', icon: bottomMenuOption.actionIcon};
            }
        }
        return {class: 'bottom-menu', icon: bottomMenuOption.icon};
    };
    const BottomMenu = isShowHeaderMobileView();


    const toggleDrawer = (open) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpenMoreDriver(open);
    };

    const renderBottomBar = () => {
        if (!BottomMenu) {
            return;
        }
        return <div className="bottom-menu-container bottom-menu-border-top"
                    style={{gap: 20}}>
            {
                bottomMenuOptions.map((bottomMenuOption) => {
                    return <NavLink to={bottomMenuOption.link}
                                    className={() => mainNavClassName(bottomMenuOption).class}>
                        <div className="d-flex flex-column align-items-center"
                             onClick={handleClickClose}
                             style={{gap: 4}}>
                            <img src={mainNavClassName(bottomMenuOption).icon}
                                 height={18}
                                 className=""/>
                            <span className="bottom-menu-name">
                                   {bottomMenuOption.title}
                            </span>
                        </div>
                    </NavLink>
                })
            }

            <div className={isMoreActive()}
                 style={{flex: 1}}
                 ref={ref}
                 onClick={handleClick}>
                <div className="d-flex flex-column align-items-center" style={{gap: 4}}>
                    <img src={MoreIcon}
                         height={16}
                         className=""/>

                    <span className="bottom-menu-name">
                      More
                    </span>
                </div>
            </div>
        </div>

    };

    return (
        <div>
            <Drawer
                anchor="bottom"
                open={openMoreDriver}
                onClose={toggleDrawer(false)}
                sx={{
                    width: '100%', maxWidth: 'unset',
                    bottom: 60,
                    '& .MuiModal-backdrop': {
                        bottom: 60
                    },
                    '& .MuiDrawer-paper': {
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        bottom: 60,
                        boxShadow: 'none'
                    }
                }}
            >
                {openMoreDriver &&  < div className="container-top-border-radius"
                                          role="presentation"
                                          onClick={toggleDrawer(false)}
                                          onKeyDown={toggleDrawer(false)}
                    // Adjust the height of the drawer
                >

                    <div className="more-container mb-2">

                        <div className="d-flex justify-content-between align-items-center mx-3">
                            <div/>
                            <div className="more-option-line"/>
                            <div className="d-flex justify-content-end">
                                <i className="fa-solid fa-xmark" style={{fontSize: "24px"}}
                                   onClick={handleClickClose}
                                />
                            </div>
                        </div>

                        {
                            moreMenuOptions.map((moreMenuOption) => {
                                return <Link className="more-container-option mx-3 my-2 p-3"
                                             to={moreMenuOption.link}
                                             onClick={handleClickClose}>
                                    <span className="more-option-menu-text">{moreMenuOption.title}</span>
                                </Link>
                            })}

                    </div>

                </div>}
            </Drawer>
            {renderBottomBar()}
        </div>
    );
}

export default BottomMenuOptionView;
