import React, {useRef, useState, useEffect} from 'react';
import {aboutUS} from "./AboutModule";
import AboutView from "./AboutView";
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";



function About(props) {
    const [aboutUsState, setAboutUSState] = useState({

        RashmiShah: false,
        rashmiMain: true,
        rashmiMain1: true,
        rashmiMain2: true,
        rashmiMain3: true,
        rashmiMain4: true,
        steveAttri: false,
        jaideep: false,
        sanketSunil: false,
        pooja: false,
        priyaNihalani: false,
        soniaPritam: false,
        richutaAgarwal: false,
        mitaliDukale: false,
        shashankShende: false,
        sameerMokashi: false,
        nitishKamdar: false,
        rupaliJadhav: false,
        Swapnil: false,
        Oasis: false,
        Aniket: false,
        Vishwas: false,
        Chaitanya: false,
        Sameer: false,
        aboutdata: [],
        testdata: [],
        team: [],
        imageUrl: [],
        mapped: [],
        department_id:"",
        teamdata: [],
        temdataid:'',
        teamdatashow: [],

        videoLink:  '',
        errMsg: 'Found error!',
        isError: false,
        isLoading: true,
        allData: [],
        metaDetails: [
            {name: 'keywords', content: 'VR Local keywords'},
            {name: 'description', content: 'index, follow'},
            {name: 'tag', content: 'VR Local'}
        ],
        department: '',
        url: '',

    });

    useEffect(()=>{
        let aboutUsData = aboutUS;
        console.log('ab', aboutUsData)
    });

    return (
        <div>
            <AboutView {...props}/>
        </div>
    );

}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(About));

