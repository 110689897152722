import React, {useState} from 'react';
import HomePageView from "./HomePageView";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";


function HomePage(props) {
    const [homeState, setHomeState] = useState({
        homeservice: [],
        profile: [],
        process: [],
        processimage: [],
        homepageforlogo: true,
        homedata: [],
        testdata: [],
    });

    return (
        <HomePageView {...props}/>
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});


export default connect(mapStateToProps, {})(withRouter(HomePage));
