import React, {useState} from 'react';
import SipView from "./SipView"
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

function Sip(props) {
    const [sipState, setSIPState] = useState({
        modelcondition: true,
        userName: '',
        monthlyInvest: '',

        yearsValue: 0,
        investment: 0,
        totalValue: 0,
        annualRate: 0,

        rateofReturn: '',
        toteleMonth: '',
        monthlyReturn: '',
        monthlyReturn_A: '',
        TotalNumberofMonths_B: '',
        TotalNumberofMonths_AB: '',
        Monthly_Return_D: '',
        TotelSIPAmount: '',


        lumsumValue: 0,
        lsAnnualReturn: 0,
        lsAnnualReturn_A: 0,
        lsNumberofYears: 0,
        lsLumpsumAmount: 0,
        buildCorpusOf_Rs: 0,
        Expect: true,
        Invest: false,

    });

    return (
        <SipView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});


export default connect(mapStateToProps, {})(withRouter(Sip));
