import React, {useEffect, useRef, useState} from 'react';
import Footer from "../../core/layout/footer/footer";
import data from "./WhitePaperModule.json";
import {Pagination} from "@mui/material";
import fileNotFoundIcon from "../../assets/img/KnowledgeBank/fileNotFoundIcon2.jpg";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {setSearchWhitepaper} from "../../core/reducers/appReducer";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function WhitePapersView(props) {
    const {isMobileView, isXSView} = props;
    const inputbox=useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 6;
    const lastPage = currentPage * recordsPerPage;
    const firstPage = lastPage - recordsPerPage;
    // const records = data.sort(function(a, b){return new Date(a.date) - new Date(b.date);}).slice(firstPage, lastPage);
    const records = data.slice(firstPage, lastPage);
    // const sortWhitePapers = data.sort(function(a, b){return a - b});
    const npages = Math.ceil(data.length / recordsPerPage);


    const [searchQuery, setSearchQuery] = useState('');
    const [searchElement, setSearchElement] = useState([]);
    const recordsSearchbox = searchElement.slice(firstPage, lastPage);
    const npagesSearchbox = Math.ceil(searchElement.length / recordsPerPage);

    const dispatch=useDispatch();
    const searchQueryStore=useSelector(state => state.appState.searchWhitepaperText)

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        dispatch(setSearchWhitepaper(query));
        console.log("this is redux",searchQueryStore);


        // Split the search query into individual words
        const queryWords = query.split(' ');
        // Filter the event array based on search query
        const filteredEvents = data.filter(event => {
            // Convert the event title to lowercase
            const title = event.title.toLowerCase();
            // Check if every word in the search query is present in the event title
            return queryWords.every(qWord => title.includes(qWord));
        });

        // Update the state with filtered results
        setSearchElement(filteredEvents);
        setCurrentPage(1);


    };
    if(searchQueryStore!=='' && searchQuery=='')
    {
        // setSearchQuery(searchQueryStore);
        handleSearch( event={
            target:{
                value:searchQueryStore
            }
    });
    }
    //to open pdf
    const openNewWindow = (props) => {
        window.open(props, '_blank')
    };

    const handlePage = (event, page) => {
        setCurrentPage(page);
        console.log('in function', page);
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This makes the scroll smooth
        });
    };
    const handleKeyPress = (event) => {
        console.log("this is handlekey",event.key)
        if (event.key === 'Enter') {

            event.preventDefault();
            if(inputbox.current)
            {
                inputbox.current.blur();
            }
        }
    };



    return (
        <div>
            <div className="topsection"/>
            <section className="container-x">
                <div className="pt-md-3 pt-0 knowledge-bank-title-pb page-title">
                    Publications
                </div>
                <div className="row">
                    <div className="col-lg-6"/>
                    <div className="col-lg-6 col-md-12">
                        <div className="row">
                            <div className="col">
                                <input type="text m-0"
                                       ref={inputbox}
                                       value={searchQuery} onChange={handleSearch}
                                       onKeyPress={handleKeyPress}
                                       className="search-box m-0"
                                       placeholder="Search"/>
                                <i className="fa fa-search"
                                   aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        searchQuery === '' ?
                            records.map((e,index)=>
                                <div key={index} className={isMobileView ? "d-flex justify-content-center col-12" : "col-4"} >
                                    <div className={isMobileView ? "card whitepaper-blogcard text-center" :'card whitepaper-blogcard text-start'}
                                         style={{width: isMobileView ? isXSView ? '100%' : '60%' : '100%'}}>
                                        <iframe src={ s3BaseURL + e.image}
                                                title={e.title}
                                                style={{height: 310, border: '2px solid #dddddd'}}/>
                                        <div className="knowledge-bank-name text-truncate py-2">
                                            {e.title}
                                        </div>
                                        <div className="date mb-3">
                                            <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                                            <span className="ps-2">
                                                    {moment(e.date, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                            </span>
                                        </div>
                                        <div className={isMobileView ? "text-center" : ''}>
                                            <button type="button"
                                                    className="primary-button"
                                                    onClick={() => openNewWindow(s3BaseURL + e.image)}>
                                                Read More
                                            </button>
                                        </div>
                                    </div>
                                </div>) :

                            recordsSearchbox.map((e,index) =>
                                <div key={index} className="col-lg-4 col-12 ">
                                    <div className={isMobileView ? "card whitepaper-blogcard text-center" :'card whitepaper-blogcard text-start'}>
                                        <iframe src={ s3BaseURL + e.image}
                                                title={e.title}
                                                style={{height: 310}}/>
                                        <div className="knowledge-bank-name text-truncate py-2">
                                            {e.title}
                                        </div>
                                        <div className="date mb-3">
                                            <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                                            <span className="ps-2">
                                                    {moment(e.date, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                            </span>
                                        </div>
                                        <div className={isMobileView ? "text-center" : ''}>
                                        <button type="button "
                                                onClick={() => openNewWindow(s3BaseURL + e.image)}
                                                className=" primary-button">
                                            Read More
                                        </button>
                                        </div>
                                    </div>
                                </div>)
                    }
                </div>
                {searchQuery === '' ?
                    <div className="d-flex justify-content-center mb-5">
                        <Pagination count={npages} onClick={scrollToTop} onChange={handlePage} color="primary"/>
                    </div>
                    : <div>
                        {(recordsSearchbox.length === 0) ?
                            <div className='container d-flex justify-content-center p-5 '>
                                <div>
                                    <div className="img-fluid text-center pb-2">
                                        <img height={76}
                                             width={76}
                                             src={fileNotFoundIcon}
                                             alt=''/>
                                    </div>
                                    <h1 className='section-description-text '>Result Not Found!</h1>
                                </div>
                            </div>
                            : <div className="d-flex justify-content-center mb-5">
                                <Pagination count={npagesSearchbox} onChange={handlePage} color="primary"/>
                            </div>
                        }
                    </div>
                }
            </section>
            {renderFooter()}
        </div>
    );
}

WhitePapersView.propTypes = {};

export default WhitePapersView;
