import React, {useEffect, useRef,useState} from 'react';
import data from "./BlogModule.json";
import {Pagination} from '@mui/material';
import Footer from "../../core/layout/footer/footer";
import {Link, withRouter} from "react-router-dom";
import moment from "moment/moment";
import fileNotFoundIcon from "../../assets/img/KnowledgeBank/fileNotFoundIcon2.jpg";

import {setSearchBlog} from "../../core/reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";


let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function BlogView(props) {
    const {isMobileView, isXSView,search} = props;
    const inputbox=useRef(null);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const recordsPerPage = 6;
    const lastPage = currentPage * recordsPerPage;
    const firstPage = lastPage - recordsPerPage;
    const records = data.slice(firstPage, lastPage);
    const npages = Math.ceil(data.length / recordsPerPage);


    const [searchQuery, setSearchQuery] = useState('');
    const [searchElement, setSearchElement] = useState([]);
    const recordsSearchbox = searchElement.slice(firstPage, lastPage);
    const npagesSearchbox = Math.ceil(searchElement.length / recordsPerPage);


    const dispatch=useDispatch();
    const searchQueryStore=useSelector(state => state.appState.searchBlogText);

    const handleSearch = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchQuery(query);

        dispatch(setSearchBlog(query));

        // Split the search query into individual words
        const queryWords = query.split(' ');
        // Filter the event array based on search query
        const filteredEvents = data.filter(event => {
            // Convert the event title to lowercase
            const title = event.heading.toLowerCase();
            // Check if every word in the search query is present in the event title
            return queryWords.every(qWord => title.includes(qWord));
        });

        // Update the state with filtered results
        setSearchElement(filteredEvents);
        setCurrentPage(1);

    };

    if(searchQueryStore!=='' && searchQuery=='') {
        // setSearchQuery(searchQueryStore);
        handleSearch(event = {
            target: {
                value: searchQueryStore
            }
        });
    }
    const handleKeyPress = (event) => {
        console.log("this is handlekey",event.key)
        if (event.key === 'Enter') {

            event.preventDefault();
            if(inputbox.current)
            {
                inputbox.current.blur();
            }
        }
    };



    const handlePage = (event, page) => {
        setCurrentPage(page);
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    console.log("this is record search", recordsSearchbox, recordsSearchbox.length, recordsSearchbox.length === 0);

    return (
        <div>
            <div className="topsection"/>
            <section className="container-x">
                <div className="pt-md-3 pt-0 knowledge-bank-title-pb page-title">
                    Articles
                </div>
                <div className="row">
                    <div className="col-lg-6"/>
                    <div className="col-lg-6 col-md-12">
                        <div className="row">
                            <div className="col">
                                <input type="text m-0 "
                                       value={searchQuery}
                                       ref={inputbox}
                                       onChange={handleSearch}
                                       onKeyPress={handleKeyPress}
                                       className="search-box m-0"
                                       placeholder="Search"/>
                                <i className="fa fa-search"
                                   aria-hidden="true"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="div-section">
                    <div className="row">
                        {
                            searchQuery === '' ?
                                records.map((e,index) => {
                                    return <div key={index} className={isMobileView ? "d-flex flex-column align-items-center text-center col-12 gap-3 pb-5" : "col-4 gap-3 pb-5 align-middle text-start"}>
                                     <div style={{width: isMobileView ? isXSView ? '100%' : '60%' : '100%'}}>
                                        <div className="d-flex align-items-center"
                                             style={{height: "310px"}}>
                                            <img className='img-fluid ' style={{
                                                objectFit: 'cover', width: '100%',
                                                height: '95%'
                                            }} src={ s3BaseURL + e.image} alt=""/>
                                        </div>
                                        <div className="d-flex justify-content-center justify-content-lg-start">
                                        <div className="knowledge-bank-name text-truncate py-2">
                                            {e.heading}
                                        </div>
                                        </div>
                                        <div className="date mb-3">
                                            <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                                            <span className='px-2 '>
                                             {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                            </span>
                                        </div>
                                        <Link to={'/knowledge-bank/articles/' + e.slug}>
                                            <button type="button" className="primary-button ">Read More</button>
                                        </Link>
                                        </div>
                                    </div>
                                })
                                :
                                recordsSearchbox.map((e,index) => {
                                    return <div key={index} className={!isMobileView ? "col-lg-4  gap-3 pb-5 align-middle " :"col-12 text-center"}>
                                        <div className="d-flex align-items-center" style={{height: "310px"}}>
                                            <img className='img-fluid ' style={{
                                                objectFit: 'cover', width: '100%',
                                                height: '95%'
                                            }} src={s3BaseURL + e.image} alt=""/>
                                        </div>
                                        <div className="knowledge-bank-name text-truncate py-2">
                                            {e.heading}
                                        </div>
                                        <div className="date mb-3 d-inline-block w-100">
                                            <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                                            <span className='px-2 '>
                                                {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                                            </span>
                                        </div>
                                        <Link to={'/knowledge-bank/articles/' + e.slug}>
                                            <button type="button" className="primary-button ">Read More</button>
                                        </Link>
                                    </div>
                                })
                        }
                    </div>
                </div>
                {

                    searchQuery === '' ?
                        <div className="d-flex justify-content-center mb-5">
                            <Pagination count={npages} onChange={handlePage} color="primary"/>
                        </div>
                        : <div>
                            {(recordsSearchbox.length === 0) ?
                                <div className='container d-flex justify-content-center p-5 '>
                                    <div>
                                        <div className="img-fluid d-flex justify-content-center pb-2">
                                            <img height={76}
                                                 width={76}
                                                 src={fileNotFoundIcon}
                                                 alt=''/>
                                        </div>
                                        <h1 className='section-description-text '>Result Not Found!</h1>
                                    </div>
                                </div>
                                : <div className="d-flex justify-content-center my-5">
                                    <Pagination count={npagesSearchbox} onChange={handlePage} color="primary"/>
                                </div>
                            }
                        </div>

                }
            </section>
            {renderFooter()}
        </div>
    );
}

BlogView.propTypes = {};

export default BlogView;
