import React, {useEffect} from 'react';
import data from "../BlogModule.json";
import {Link} from "react-router-dom";
import moment from "moment";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function ShowMoreBlogs(props) {
    // const {isMobileView}=props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This makes the scroll smooth
        });
    };

    const records = data.slice(0, 3);
    return (
        <div>
            <div className="section-title"> MORE RECENT ARTICLES</div>
            <div className="row">
                {records.map(e => {
                    return <div className="col-lg-4 col-md-6 gap-3 pb-5 align-middle text-center text-lg-start">
                        <div className="d-flex align-items-center" style={{height: "310px"}}>
                            <img className='img-fluid ' style={{
                                objectFit: 'cover', width: '100%',
                                height: '95%'
                            }} src={s3BaseURL + e.image} alt=""/>
                        </div>
                        <div className="knowledge-bank-name text-truncate" style={{paddingBottom: 5}}>
                            {e.heading}
                        </div>
                        <div className="date mb-3">
                            <i className="fa-regular fa-calendar"/>
                            <span className='px-2 '>
                                {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                            </span>
                        </div>
                        <Link to={'/knowledge-bank/articles/' + e.slug}>
                            <button type="button" onClick={scrollToTop}
                                    className="primary-button ">Read More
                            </button>
                        </Link>
                    </div>
                })
                }
            </div>
        </div>
    );
}

export default ShowMoreBlogs;
