import React, {useState} from 'react';
import BlogView from "./BlogView";
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";

function Blog(props) {
    const [blogsPageState, sstBlogsPageState] = useState({

            Blogs: [],
            videoLink: '',
            errMsg: 'Found error!',
            isError: false,
            isLoading: true,
            allData: [],
            metaDetails: [
                {name: 'keywords', content: 'VR Local keywords'},
                {name: 'description', content: 'index, follow'},
                {name: 'tag', content: 'VR Local'}
            ],
            department: '',
            url: '',
        })
    ;

    return (
        <BlogView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    searchBlogText: state.appState.searchBlogText,
});

export default connect(mapStateToProps, {})(withRouter(Blog));
