import React from 'react';
import WealthCreationView from "./WealthCreationView";

function WealthCreation(props) {
    return (
        <WealthCreationView/>
    );
}

export default WealthCreation;
