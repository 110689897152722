import React, {useEffect} from 'react';
// import StockImg from '../../assets/image/Products/stocks.jpg';
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function StockView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="page-title mb-3">
                                Stocks
                            </div>
                            <div className="page-sub-title mb-3">
                                Investing in companies that change people's lives
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/products/stocks.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">
                                Simplicity’s experience and knowledge base is global, in addition to that of the
                                Indian market. This provides an edge when making selections.
                            </div>
                            <div className="content mb-3">
                                Our investment philosophy is time tested and works well even during volatile
                                times. We restrict the number of stock selection to less than 10-15, depending
                                on the size of the portfolio. We focus on long-term gain and not short-term
                                speculation.
                            </div>
                            <div className="content mb-3">
                                Our recommendations are based on the quality of the businesses and their
                                management.
                            </div>
                            <div className="content mb-3">
                                We do not offer day trading or options and derivatives as investment products. We
                                are process driven and do not support timing of the market and random investing.
                                Our approach is driven by the fundamentals of the company, and the sector.
                            </div>
                        </div>
                        <div className=" col-lg-6 col-12">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/products/stocks.jpg'}
                                     className="img-fluid "
                                     alt="none"/>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default StockView;
