import React from 'react';
import data from '../BlogModule.json'
import {useParams} from "react-router-dom";
import ShowMoreBlogs from "./ShowMoreBlogs";
import UnionBudget from "./UnionBudget";
import ConsistencyBetter from "./ConsistencyBetter";
import RelationshipEmotional from "./RelationshipEmotional";
import HowIdentity from "./HowIdentity";
import MostImpComponent from "./MostImpComponent";
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";
let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function SubBlogView(props) {
    const {isMobileView, isXSView} = props;

    const { id } = useParams();
    const element=data.filter(e=>{if(e.slug===id ){  return e }});
    console.log(element)

    const renderUI=()=> {
        switch (id) {
            case 'union-budget-2023':
                return <UnionBudget value={element}/>;
            case 'consistency-is-far-better-than-rare-moments-of-greatness':
                return <ConsistencyBetter value={element}/>;
            case 'relationship-between-emotions-truth-and-the-decisions-we-take':
                return <RelationshipEmotional value={element}/>;
            case 'how-can-you-identify-a-good-financial-advisor-the-answer-is-easier-than-you-think':
                return <HowIdentity value={element}/>;
            case 'the-most-important-question-of-your-life':
                return <MostImpComponent value={element}/>;
            default:
                return null;
        }
    }

    return (
        <div className='container h-100vh'>
            <div className="topsection"/>
            <section className="blog_sec comman">
                <div className=''>
                    {renderUI()}
                </div>
                <ShowMoreBlogs/>
            </section>
        </div>
);
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(SubBlogView));
