import React, {useState} from 'react';
import "../../assets/css/SelectYourProfile.css";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";
import ForHerView from "../forHer/ForHerView";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function GlobalView(props) {
    const [showUI, setShowUI] = useState(false);
    const {isMobileView, isXSView} = props;


    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };
    return (
        <div>
            <div className='topsection'/>
            {!showUI && <div className="container-x">
                <div className="bg-global">
                    <div className="head-block-select-para">
                        <div className="page-title my-3 text-center">
                            Investment assets
                            in different countries ?
                        </div>
                        <div className="para-select-profile mb-4">
                            We can simplify your taxes and estate plan
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-blue"
                                    onClick={displayUI}>
                                Read More
                            </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/globalPrimaryBg.jpg'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>
            }
            {showUI && <div>
                <div className="container-x"
                     style={{height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="page-title my-3">
                                Create a legacy for all to remember
                            </div>
                            <div className="content mb-3">
                                Having investments spread across the world can be complicated. We also know
                                that you probably have some biases , multiple advisors, and gaps in knowledge
                                about tax and legacy planning.
                            </div>
                            <div className="content mb-3">
                                We will create a vision with you that is based on your objectives and goals. Your
                                estate plan will then be designed in such a way that each country’s assets are
                                considered, optimized for taxes – and clearly fit with your legacy plan.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 ">
                            <div className="d-none d-lg-block">
                                <img src={s3BaseURL + '/selectYourProfile/globalSecondaryBg.jpg'}
                                     className="img-fluid"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            }
            {renderFooter()}
        </div>
    );
}

GlobalView.propTypes = {};

export default GlobalView;
