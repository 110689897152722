import React, {useEffect, useState} from 'react';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import SipView from "../SipView";
import "../../../assets/css/SIP.css"
import Disclaimer from "./Disclaimer";

function ExpectView(props) {
    const {isMobileView, isXSView} = props;

    const [sipState, setSipState] = useState({
        inputMyGoal: 0,
        inputNoOfYear: 0,
        inputExpectRate: 0,
        llssInvestment: 0
    });

    const [llssTotelSIPAmountReturn, setLlssTotelSIPAmountReturn] = useState(0);
    const [llsslsLumpsumAmount, setLlsslsLumpsumAmount] = useState(0);

    const llssgetToteleCalcutation = () => {
        setSipState(prevState => ({
            ...prevState,
            llssInvestment: prevState.inputMyGoal
        }));

        // Use the latest state values
        setSipState(prevState => {
            const llssYearsValue = prevState.inputNoOfYear; // Default value, can be set from state or props
            const llssAnnualRate = prevState.inputExpectRate; // Default value, can be set from state or props

            const llsstotaleMonth = llssYearsValue * 12;
            const llssmonthlyReturn = llssAnnualRate / 12 / 100;
            const llssmonthlyReturn_A = llssAnnualRate / 12 / 100 + 1;
            const llssTotalNumberofMonths_B = Math.pow(llssmonthlyReturn_A, llsstotaleMonth);
            const llssTotalNumberofMonths_AB = llssTotalNumberofMonths_B - 1;

            const llssMonthly_Return_D = llssTotalNumberofMonths_AB / llssmonthlyReturn;
            const llssTotelSIPAmountReturn = prevState.llssInvestment / (llssMonthly_Return_D * llssmonthlyReturn_A);

            const llsslsAnnualReturn = llssAnnualRate / 100;
            const llsslsAnnualReturn_A = llsslsAnnualReturn + 1;
            const llsslsNumberofYears = Math.pow(llsslsAnnualReturn_A, llssYearsValue);
            const llsslsLumpsumAmount = prevState.llssInvestment / llsslsNumberofYears;

            // Update the calculations
            setLlssTotelSIPAmountReturn(Math.round(llssTotelSIPAmountReturn) || 0);
            setLlsslsLumpsumAmount(Math.round(llsslsLumpsumAmount) || 0);

            return prevState; // Return the updated state
        });
    };

    useEffect(() => {
        llssgetToteleCalcutation();
        console.log(llsslsLumpsumAmount, llssTotelSIPAmountReturn, sipState.inputMyGoal);
    }, [sipState.inputMyGoal, sipState.inputExpectRate, sipState.inputNoOfYear]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const removeZerofn = (number) => {
        return number.replace(/-?\b0\b|-?\d+\.\d*\b0*\b|-[1-9]\d*(\.\d+)?|\b0(\.\d+)?/g, 0);
    };

    const setInputMyGoalfn = ((e) => {
        if (e.target.value >= 0 && e.target.value <= 500000000) {
            setSipState(prevState => ({
                ...prevState,
                inputMyGoal: removeZerofn(e.target.value) // Update inputMyGoal
            }));
        } else {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputMyGoal: 0,
            }));
        }
    });

    const setInputNoOfYearfn = ((e) => {
        if (e.target.value >= 0 && e.target.value <= 50) {
            setSipState(prevState => ({
                ...prevState,
                inputNoOfYear: removeZerofn(e.target.value)
            }));
        } else {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputNoOfYear: 0,
            }));
        }
    });

    const setInputExpectRatefn = ((e) => {
        if (e.target.value >= 0 && e.target.value <= 30) {
            setSipState(prevState => ({
                ...prevState,
                inputExpectRate: removeZerofn(e.target.value)
            }));
        } else {
            setSipState((prevInputs) => ({
                ...prevInputs,
                inputExpectRate: 0,
            }));
        }
    });

    const renderUI = () => {
        if (isMobileView) {
            console.log("isMobileView", isMobileView)
            return (
                <div className="h-100">
                    <div className="container-x">
                        <div className="d-flex align-items-center" style={{gap: 10}}>
                            <div style={{flex: 1}}>
                                <label className="input-label">My Goal</label>
                                <input value={sipState.inputMyGoal}
                                       type={"number"}
                                       min={500000}
                                       max={500000000}
                                       onChange={setInputMyGoalfn}
                                       onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                           ...prevState,
                                           inputMyGoal: 0
                                       })) : ''}
                                       className="input-field m-0"/>
                            </div>
                            <div style={{flex: 1}}>
                                <label className="input-label">No. of years</label>
                                <input value={sipState.inputNoOfYear}
                                       type={"number"}
                                       min={1}
                                       max={50}
                                       onChange={setInputNoOfYearfn}
                                       onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                           ...prevState,
                                           inputNoOfYear: 0
                                       })) : ''}
                                       className="input-field m-0"/>
                            </div>
                        </div>
                        <div className="d-flex align-items-center" style={{marginTop: 30}}>
                            <div style={{flex: 1}}>
                                <label className="input-label">Expected Rate of Return</label>
                                <div>
                                    <input value={sipState.inputExpectRate}
                                           type={"number"}
                                           min={5}
                                           max={30}
                                           onChange={setInputExpectRatefn}
                                           onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                               ...prevState,
                                               inputExpectRate: 0
                                           })) : ''}
                                           className="input-field "/>
                                </div>
                            </div>
                            <div style={{flex: 1}}/>
                        </div>
                    </div>
                    <div className="bg-black-section my-3 px-4 py-3 h-100">
                        <div className="d-flex align-items-center justify-content-between  sip-calc" >
                            <h5>My Goal</h5>
                            <h1>₹ {formatNumber(sipState.inputMyGoal) || 0}</h1>
                        </div>
                        <div>
                            <div className="d-flex align-items-center justify-content-between sip-calc">
                                <h5>Monthly SIP Required</h5>
                                <h1>₹ {formatNumber(llssTotelSIPAmountReturn) || 0}</h1>
                            </div>
                            <div className="sip-calc">
                                <h5>OR</h5>
                            </div>
                            <div className="d-flex align-items-center justify-content-between sip-calc">
                                <h5>Lump sum invest</h5>
                                <h1>₹ {formatNumber(llsslsLumpsumAmount) || 0}</h1>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className="btn btn-light btn-lg  mt-5 font-bolder w-50 ">
                                <Link to='/contact-us'>Invest Now</Link>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center ">
                        <span className="py-2 view-disclaimer-text" data-bs-toggle="modal"
                              data-bs-target="#exampleModal">
                            View Disclaimer
                        </span>
                    </div>
                </div>
            )
        }
        return (
            <div className="container-x">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <div>
                            <div className="w-100 mb-4">
                                <label className="input-label">My Goal</label>
                                <div className="slider-position">
                                    <div className="slider-css"/>
                                    <input value={sipState.inputMyGoal === '' ? 0 : sipState.inputMyGoal}
                                           style={{
                                               background: `linear-gradient(to right, #757575 ${((sipState.inputMyGoal - 500000) / (500000000 - 500000)) * 100}%, #ddd ${((sipState.inputMyGoal - 500000) / (500000000 - 500000)) * 100}%)`,
                                           }}
                                           onChange={e => setSipState(prevState => ({
                                               ...prevState,
                                               inputMyGoal: removeZerofn(e.target.value)
                                           }))}// Update inputMyGoal
                                           name="llsslumSumAmount" type="range"
                                           min="0"
                                           max="500000000"/>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="range-span-text">5L</span>
                                    <span className="range-span-text">50Cr</span>
                                </div>
                                <div>
                                    <input value={sipState.inputMyGoal}
                                           type={"number"}
                                           min={500000}
                                           max={500000000}
                                           onChange={setInputMyGoalfn}
                                           onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                               ...prevState,
                                               inputMyGoal: 0
                                           })) : ''}
                                           className="input-field m-0"/>
                                </div>
                            </div>

                            <div className="w-100 mb-4">
                                <label className="input-label">No. of years</label>
                                <div className="slider-position">
                                    <span className="slider-css"/>
                                    <input id=""
                                           value={sipState.inputNoOfYear === '' ? 0 : sipState.inputNoOfYear}
                                           onChange={e => setSipState(prevState => ({
                                               ...prevState,
                                               inputNoOfYear: removeZerofn(e.target.value)
                                           }))}
                                           name="llsslumSumAmount" type="range"
                                           min="0"
                                           max="50"
                                           style={{
                                               background: `linear-gradient(to right, #757575 ${((sipState.inputNoOfYear - 0) / (50 - 0)) * 100}%, #ddd ${((sipState.inputNoOfYear - 0) / (50 - 0)) * 100}%)`,
                                           }}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="range-span-text">1 Year</span>
                                    <span className="range-span-text">50 Years</span>
                                </div>
                                <div>
                                    <input value={sipState.inputNoOfYear}
                                           type={"number"}
                                           min={1}
                                           max={50}
                                           onChange={setInputNoOfYearfn}
                                           onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                               ...prevState,
                                               inputNoOfYear: 0
                                           })) : ''}
                                           className="input-field m-0"/>
                                </div>
                            </div>

                            <div className="w-100">
                                <label className="input-label">Expected Rate of Return</label>
                                <div className="slider-position">
                                    <span className="slider-css"/>
                                    <input value={sipState.inputExpectRate === '' ? 0 : sipState.inputExpectRate}
                                           onChange={e => setSipState(prevState => ({
                                               ...prevState,
                                               inputExpectRate: removeZerofn(e.target.value)
                                           }))}
                                           name="inputExpectRate"
                                           type="range"
                                           min="0"
                                           max="30"
                                           style={{
                                               background: `linear-gradient(to right, #757575 ${((sipState.inputExpectRate) / (30)) * 100}%, #ddd ${((sipState.inputExpectRate) / (30)) * 100}%)`,
                                           }}
                                    />
                                </div>
                                <div className="d-flex justify-content-between">
                                    <span className="range-span-text">5%</span>
                                    <span className="range-span-text">30%</span>
                                </div>
                                <div>
                                    <input value={sipState.inputExpectRate}
                                           type={"number"}
                                           min={5}
                                           max={30}
                                           onChange={setInputExpectRatefn}
                                           onBlur={e => e.target.value === '' ? setSipState(prevState => ({
                                               ...prevState,
                                               inputExpectRate: 0
                                           })) : ''}
                                           className="input-field"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-12">
                        <div className="bg-black-section p-4 text-center h-100 d-flex flex-column justify-content-center">
                            <div className="sip-calc mb-3">
                                <h5>My Goal</h5>
                                <h1>₹ {formatNumber(sipState.inputMyGoal) || 0}</h1>
                            </div>
                            <div className="d-flex">
                                <div className="sip-calc" style={{flex: 1}}>
                                    <h5>Monthly SIP Required</h5>
                                    <h1>₹ {isFinite(llssTotelSIPAmountReturn) ? formatNumber(llssTotelSIPAmountReturn) || 0 : '∞'}</h1>
                                </div>
                                <div className="sip-calc" style={{flex: 0.2}}>
                                    <h5>OR</h5>
                                </div>
                                <div className="sip-calc" style={{flex: 1}}>
                                    <h5 style={{height: isMobileView ? " " : "40px"}}>Lump sum invest</h5>
                                    <h1>₹ {formatNumber(llsslsLumpsumAmount) || 0}</h1>
                                </div>
                            </div>
                            <div className="d-flex justify-content-center">
                            <div className="btn btn-light btn-lg  mt-5 font-bolder">
                                <Link to='/contact-us'>Invest Now</Link>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <button className="btn btn-outline-dark my-4"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal">View Disclaimer
                    </button>
            </div>
        )
    };

    return (
        <>
            {renderUI()}
        </>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(ExpectView));

