import React from 'react';

function SignUp(props) {
    const [signUpPageState, setSignUpPageState] = useState({
    siteKey:'',
    signupForm: 'FormGroup',
    submitted : false,
    isSubmittingForm : false,
    messageResponse: [],
    formData :new FormData(),

});
    return (
        <SignUpView/>
    );
}

export default SignUp;
