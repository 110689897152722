import React, {useEffect, useState} from 'react';
import data from '../CitySection';
function CitySection(props) {
    const [cityState, setCityState] = useState({
        city: "PUNE",
        name: null,
        email: null,
        contactNo: 0,
        address: null
    });

    useEffect(() => {
        const selectedCityData = data.find(item => item.city === cityState.city);
        if (selectedCityData) {
            setCityState(prevState => ({
                ...prevState,
                name: selectedCityData.name,
                email: selectedCityData.email,
                contactNo: selectedCityData.contactNo,
                address: selectedCityData.address
            }));
        }
    }, [cityState.city, data]);

    const handleCityChange = (e) => {
        const selectedCity = e.target.value;
        setCityState(prevState => ({
            ...prevState,
            city: selectedCity
        }));
    };

    return (
        <div className="p-4 section-bg">
            <div className="section-description-text mb-4 ">
                Select your city to connect with our team member
            </div>
            <div className="inputtype">
                  <div className="form-group mr-2">
                        <select className="form-control custom-select "
                                onChange={handleCityChange}
                                value={cityState.city}>
                            {data.map((e, index) => (
                                <option key={index} value={e.city}>
                                    {e.city}
                                </option>
                            ))}
                        </select>
                    </div>
            </div>
            <div className="name">
                <h3 className="section-description-text text-start">
                    {cityState.name}
                </h3>
                <div className="email icon_list">
                    <i className="fa fa-envelope-o" aria-hidden="true" />
                    <div>
                        <div className="section-header text-start">Email id</div>
                        <div className="content py-2 text-start">
                            {cityState.email}
                        </div>
                    </div>
                </div>
                <div className="call icon_list">
                    <i className="fa fa-phone" aria-hidden="true" />
                    <div>
                        <div className="section-header text-start">Call us</div>
                        <div className="content py-2 text-start">
                            {cityState.contactNo}
                        </div>
                    </div>
                </div>
                <div className="web icon_list">
                    <i className="fa fa-globe" aria-hidden="true" />
                    <div>
                        <div className="section-header text-start">Corporate Headoffice</div>
                        <div className="content py-2 text-start">
                            {cityState.address}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CitySection;
