import React, {useEffect} from 'react';
import Footer from "../../core/layout/footer/footer";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;


function GlobalTaxAdvisoryView(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };

    return (
        <div>
            <div className="topsection"/>
            <div className="main-container pt-lg-4 pt-0">
                <div className="container-x">
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="page-title mb-3">
                                Global Tax Advisory
                            </div>
                            <div className="page-sub-title mb-3">
                                Managing tax from income in different countries for people
                                retired in India
                            </div>
                            <div className="img-block px-5 d-lg-none d-block">
                                <img src={s3BaseURL + '/homepage/globalTaxAdvisory.jpg'}
                                     className="img-fluid bannerImg mb-3"
                                     alt=""/>
                            </div>
                            <div className="content mb-3">
                                A modern Indian professional, in his lifetime has worked and
                                lived in 3 different countries. Today,
                                India has about 30 million families who are working
                                abroad and 70% of them are going to return and
                                retire in India. These Indians have assets and
                                income in different countries when they retire.
                            </div>
                            <div className="content mb-3"> Simplicity has a team of professionals who will
                                provide service on taxation from different sources of
                                income from different countries.
                            </div>
                            <div className="content mb-3">We also provide
                                services to Indian residents to support their family
                                members living abroad, based on income generated
                                from India investments.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="img-block px-5 d-lg-block d-none">
                                <img src={s3BaseURL + '/homepage/globalTaxAdvisory.jpg'}
                                     alt=""
                                     className="img-fluid bannerImg"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {renderFooter()}
        </div>
    );
}

export default GlobalTaxAdvisoryView;
