import React, {useEffect, useState} from 'react';
import "../../assets/css/SelectYourProfile.css";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import Footer from "../../core/layout/footer/footer";
import BusinessView from "../business/BusinessView";

let s3BaseURL = process.env.REACT_APP_S3_PUBLIC_BASE_IMAGE_URL;

function ForHerView(props) {
    const {isMobileView, isXSView} = props;
    const [showUI, setShowUI] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);


    const displayUI = () => {
        setShowUI(true);
        window.scrollTo(0, 0)
    };

    const renderFooter = () => {
        return (
            <Footer {...props}/>
        );
    };

    return (
        <div>
            <div className='topsection'/>
            {!showUI &&
            <div className="container-x">
                <div className="bg-for-her">
                    <div className="head-block-select-para">
                        <div className="page-title my-3 text-center">
                            Get better control of your future
                        </div>
                        <div className="para-select-profile mb-4">
                            A clear understanding of your investments will<br/>
                            increase your financial security
                        </div>
                        <div className="d-flex justify-content-center">
                            <Button className="secondary-button icon-violet"
                                    onClick={displayUI}>
                                Read More
                            </Button>
                        </div>
                    </div>
                </div>
                {isMobileView ?
                    <div>
                        <img src={s3BaseURL + '/selectYourProfile/forHerPrimaryBg.jpg'}
                             className="img-fluid bannerImg "
                             alt=""/>
                    </div>
                    :
                    <div/>
                }
            </div>

            }
            {showUI && <div>
                <div className="container-x"
                     style={{ height: isMobileView ? '' : "calc(100dvh - 140px)"}}>
                    <div className="row">
                        <div className="col-lg-6 col-12">
                            <div className="page-title my-3">
                                Financial knowledge can be liberating
                            </div>
                            <div className="content mb-3">
                                Women face unique challenges in achieving financial
                                wellness. To ensure your own
                                financial security, the more you know about your
                                finances, the better. This includes an
                                understanding of your current finances and how to
                                plan for the future.
                            </div>
                            <div className="content mb-3">
                                We will partner with you, and help you build your
                                financial confidence.
                            </div>
                            <div className="content mb-3">
                                You will likely find the improvement in your
                                financial knowledge to be a liberating
                                experience.
                            </div>
                            <div className="contact-us-text mb-3">Get in touch with us
                                <a href="/contact-us" className="contact-us">Click Here</a>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12 ">
                            <div className="d-none d-lg-block">
                                <img src={s3BaseURL + '/selectYourProfile/forHerSecondaryBg.jpg'}
                                     className="img-fluid"
                                     alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {renderFooter()}
        </div>
    )
}

ForHerView.propTypes = {};

export default ForHerView;
