import React from 'react';
import BusinessView from "./BusinessView";
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";


function Business(props) {

    const [business, setBusiness] = React.useState({
    logoApi: [],
    eventtId:'',
    profile:[],
    businessValue:'',
    business:false,
    businesstop: true,
});
    return (

            <BusinessView {...props}/>
    );
}

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(Business));
