import React from 'react';
import data from "../EventModule.json";
import moment from "moment/moment";
import {Link} from "react-router-dom";

function ShowMoreEvent(props) {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // This makes the scroll smooth
        });
    };
    const records = data.slice(0, 3);
    return (
        <div>

            <h1 className="section-title"> MORE EVENTS</h1>
            <div className="row">
                {records.map((e, index) => {
                    return <div key={index}
                                className="col-lg-4 col-12 d-flex flex-column  justify-content-lg-start justify-content-center text-lg-start text-center"
                                style={{marginBottom: '94px'}}>
                        <div className="video_plyar">
                            <iframe
                                src={e.url}
                                style={{aspectRatio: 16 / 9}}
                                className="img-fluid w-100"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />
                        </div>
                        <div className="d-flex d-lg-block justify-content-center">
                            <div className="knowledge-bank-name text-truncate py-2" >
                                {e.title}
                            </div>
                        </div>
                        <div className="date mb-3">
                            <i className="fa-regular fa-calendar-days" aria-hidden="true"/>
                            <span className='ps-2 '>
                                {moment(e.created_at, "DD MMM YYYY, hh:mm A").format("MMM D, YYYY")}
                            </span>
                        </div>
                        <Link to={'/knowledge-bank/events/' + e.id}>
                            <button type="button" onClick={scrollToTop}
                                    className="primary-button text-nowrap ">Click To
                                View
                            </button>
                        </Link>
                    </div>

                })
                }

            </div>
        </div>
    );
}

export default ShowMoreEvent;
