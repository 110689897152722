import React from 'react';
import {connect} from "react-redux";
import {Link, withRouter, NavLink} from "react-router-dom";

function MyAccount(props) {
    const {isMobileView, isXSView} = props;


    return (

                <div className="h-100 d-flex align-items-center justify-content-center">
                    <div className="d-flex flex-column"
                         style={{gap:10}}>
                        <a  href={"https://wealthelite.in/client-login"}
                               target="_blank"
                            className="products-button">
                            Mutual Funds
                        </a>
                        <a href={"https://www.angelone.in/login/"}
                              target="_blank"
                            className="products-button">
                            Direct Equity
                        </a>
                    </div>
                </div>

    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isMobileDevice: state.appState.deviceInfo.isMobileDevice,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(withRouter(MyAccount));
